import Vue from "vue";
import titleMixin from "@/mixins/title";
export default Vue.extend({
    mixins: [titleMixin],
    data() {
        return {
            title: this.$t("acquisitionChannels.create.title"),
            acquisitionChannel: {
                name: "",
                description: ""
            },
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        rangeLength: [3, 50]
                    },
                    description: {
                        maxLength: 255
                    }
                }
            }
        };
    },
    methods: {
        create() {
            return this.$store.dispatch("acquisitionChannel/create", {
                acquisitionChannel: this.acquisitionChannel
            });
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("acquisitionChannels.create.toastSuccessTitle"), this.$t("acquisitionChannels.create.toastSuccessContent", {
                name: this.acquisitionChannel.name
            }));
            return this.$router.push("/acquisition-channels");
        },
        cancel() {
            return this.$router.push("/acquisition-channels");
        }
    }
});
